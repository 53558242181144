<template>
  <v-dialog
    v-model="receiveModalData.dialog"
    max-width="1650px"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formReceiveTitle }} </span>
            <v-btn
              @click="handleCloseModalForm"
              style="float: right; cursor: pointer"
              icon
              color="#26223c"
            >
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form v-model="formValid" ref="form">
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-btn
                  v-if="
                    (permissionCan('create') && !formReceiveModel.id) ||
                    permissionCan('update')
                  "
                  color="primary"
                  dark
                  class="mb-2"
                  @click="handleSaveModalForm"
                >
                  Receive
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <div v-if="formReceiveModel.owner_name">
                  <p>User: {{ formReceiveModel.owner_name }}</p>
                  <p>Received: {{ formReceiveModel.created_at }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="6" md="6">
                <v-select
                  v-model="formReceiveModel.storage_id"
                  :items="storageCollection"
                  :rules="requiredRules"
                  label="Select
                storage"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                  :error-messages="messages['storage_id']"
                  @keyup="messages['storage_id'] = ''"
                  :disabled="
                    formReceiveModel.entities.length > 0 || formReceiveModel.id
                  "
                />
              </v-col>
            </v-row>
            <v-form v-model="formItemValid" ref="formItem">
              <v-row>
                <v-col cols="2" sm="2" md="3">
                  <v-combobox
                    v-if="!formReceiveModel.id && formReceiveModel.storage_id"
                    :items="itemCollection"
                    v-model="itemInputData.item"
                    item-text="ReferenceName"
                    :label="searchInpuText"
                    :return-object="true"
                    :disabled="isEditedItemData"
                    :error-messages="messages['item']"
                    @input="handleInputItemSearch"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No item matching! Press <kbd>enter</kbd> to search
                            serial number
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <v-text-field
                    v-if="!formReceiveModel.id && formReceiveModel.storage_id"
                    v-model="itemInputData.serial_number"
                    label="Serial number"
                    :id="dynamicID"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-text-field
                    v-if="!formReceiveModel.id && formReceiveModel.storage_id"
                    v-model="itemInputData.quantity"
                    label="Quantity"
                    :rules="requiredRules"
                    :id="dynamicID"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-text-field
                    v-if="
                      !formReceiveModel.id &&
                      itemInputData.item &&
                      formReceiveModel.storage_id
                    "
                    v-model="itemInputData.item.quantityUnit"
                    label="Quantity unit"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="2" sm="2" md="2">
                  <v-select
                    v-if="
                      statuses.system &&
                      !formReceiveModel.id &&
                      formReceiveModel.storage_id
                    "
                    v-model="itemInputData.status"
                    :items="adjustmentStatuses.stock['receive_itemEntity']"
                    label="State"
                    item-text="translated"
                    item-value="key"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-btn
                    v-if="
                      (permissionCan('create') &&
                        formReceiveModel.storage_id &&
                        !formReceiveModel.id) ||
                      (permissionCan('update') &&
                        formReceiveModel.storage_id &&
                        !formReceiveModel.id)
                    "
                    color="primary"
                    dark
                    class="mb-2"
                    @click="handleAddItemToList"
                  >
                    Add to list
                  </v-btn>
                  <v-btn
                    v-if="
                      (permissionCan('create') &&
                        formReceiveModel.storage_id &&
                        !formReceiveModel.id) ||
                      (permissionCan('update') &&
                        formReceiveModel.storage_id &&
                        !formReceiveModel.id)
                    "
                    :disabled="!cancelItem"
                    class="mb-2"
                    @click="handleCancelItem"
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
              <row v-if="entitySelected">
                <v-col cols="12" sm="12" md="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Reference</th>
                          <th>Item name</th>
                          <th>Serial number</th>
                          <th>Quantity</th>
                          <th>Quantity unit</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ entitySelected.item_entity_id }}</td>
                          <td>{{ entitySelected.reference_number }}</td>
                          <td>{{ entitySelected.nameTranslated }}</td>
                          <td>{{ entitySelected.serial_number }}</td>
                          <td>{{ entitySelected.quantity }}</td>
                          <td>{{ entitySelected.quantityUnitTranslated }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </row>
            </v-form>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="headers"
                  :items="formReceiveModel.entities"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Entities</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5"
                            >Are you sure you want to delete this
                            item?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDelete"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItemConfirm"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>

                  <template v-slot:item.name="{ item }">
                    <!-- <span> {{ item.item.name }}</span> -->
                    <span> {{ item.nameTranslated }}</span>
                  </template>

                  <template v-slot:item.reference="{ item }">
                    <!-- <span> {{ item.item.reference }}</span> -->
                    <span> {{ item.reference_number }}</span>
                  </template>

                  <template v-slot:item.quantityUnit="{ item }">
                    <!-- <span> {{ item.item.quantityUnit }}</span> -->
                    <span> {{ item.quantityUnitTranslated }}</span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      v-if="!formReceiveModel.id"
                      :disabled="itemInputData.item_id"
                      small
                      class="mr-2"
                      @click="handleClickEntity(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                      v-if="!formReceiveModel.id"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  :disabled="formReceiveModel.id"
                  v-model="formReceiveModel.comment"
                  label="Comment"
                />
              </v-col>
            </row>
          </v-form>
          <!-- *******************
Etity dialog
*******************-->
          <v-dialog v-model="entityModal" persistent max-width="1200px">
            <v-card>
              <v-card-title>
                <v-toolbar flat dense>
                  <v-toolbar-title>Select entity</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="entityModal = false"
                    style="float: right; cursor: pointer"
                    icon
                    color="#26223c"
                  >
                    <v-icon> mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-text-field
                    v-model="searchEntity"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-data-table
                        :headers="headersEntity"
                        :items="entitiesArray"
                        :search="searchEntity"
                        v-model="selectedEntities"
                        item-key="item_entity_id"
                        show-select
                        class="elevation-1"
                        :items-per-page="-1"
                        :hide-default-footer="true"
                        disable-pagination
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="entityModal = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="handleAddToListSelected"
                  >Add to list</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="handleCloseModalForm">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { ENDPOINT } from "./Receives";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

const TRANSLATED_ATRIBUTES = [];

export const initialReceiveFormData = () => ({
  id: null,
  storage_id: null,
  owner_id: null,
  owner_name: null,
  created_at: null,
  entities: [],
  comment: "",
});

export const initialItemInputData = {
  item_entity_id: null,
  item: null,
  quantityUnit: null,
  serial_number: null,
  quantity: null,
  status: null,
  old_status: null,
};

export default {
  name: "ReceivesForm",
  props: ["receiveModalData", "receivedData"],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formReceiveModel: Object.assign({}, initialReceiveFormData()),
      formValid: false,
      formItemValid: false,
      itemInputData: Object.assign({}, initialItemInputData),
      editedItem: Object.assign({}, initialItemInputData),
      editedIndex: null,
      dialogDelete: false,
      entityModal: false,
      entitySelected: null,
      entitiesArray: [],
      selectedEntities: [],
      cancelItem: null,
      searchEntity: "",
      permissions: [],
      headersEntity: [
        { text: "ID", value: "item_entity_id" },
        { text: "Reference", value: "reference_number" },
        { text: "Item name", value: "nameTranslated" },
        { text: "Serial number", value: "serial_number" },
        { text: "Status", value: "statusTranslated" },
        { text: "Original quantity", value: "original_quantity" },
        { text: "Quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnitTranslated" },
        { text: "Storage", value: "storage" },
        { text: "Reserved at", value: "reserved_at" },
        { text: "Reserved by", value: "reserved_by_name" },
      ],

      headers: [
        { text: "ID", value: "item_entity_id" },
        // { text: "Type of adjustment", value: "typeOf" },
        { text: "Reference", value: "reference" },
        { text: "Item name", value: "name" },
        { text: "Old serial number", value: "old_serial_number" },
        { text: "Serial number", value: "serial_number" },
        { text: "Old state", value: "oldStatusTranslated" },
        { text: "New state", value: "statusTranslated" },
        { text: "Old quantity", value: "old_quantity" },
        { text: "New quantity", value: "quantity" },
        { text: "Quantity unit", value: "quantityUnit" },
        // { text: "Comment", value: "comment" },
        { text: "Action", value: "actions" },
      ],

      messages: {},
      stringRules: [
        (v) => !!v || "Field is required",
        (v) => v.length < 25 || "Field must be max 16 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
    };
  },
  computed: {
    ...mapGetters(["statuses", "types", "itemCollection", "storageCollection"]),
    adjustmentStatuses() {
      if (this.statuses) {
        let statuses = this.statuses;

        this.statuses.stock["receive_itemEntity"].map((item) => {
          item.translated = this.getTranslatedStatus(item.key);
          return item;
        });
        return statuses;
      }
      return [];
    },

    statusesEdit() {
      return this.statuses.stock.itemEntity;
    },

    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formReceiveTitle() {
      return this.formReceiveModel.id
        ? "View receive" + " - " + this.formReceiveModel.rc_number
        : "New receive";
    },

    isEditedItemData() {
      return this.itemInputData.item_id;
    },
    searchInpuText() {
      return "Search entity: reference, item name, project, so";
    },
  },

  watch: {
    "receiveModalData.dialog": {
      deep: true,
      handler(value) {
        if (value && !this.receiveModalData.editedId) {
          this.itemInputData = Object.assign({}, initialItemInputData);
          this.formReceiveModel = Object.assign({}, initialReceiveFormData());
          if (
            this.receivedData &&
            // this.receivedData.entities.length > 0 &&
            this.receivedData.storage_id
          ) {
            // this.formReceiveModel.entities = this.receivedData.entities;
            // let data = this.formReceiveModel.entities.map((item) => {
            //   return item.item_entity_id;
            // });

            // if (!this.formReceiveModel.id && data.length > 0) {
            // if (!this.formReceiveModel.id) {
            //   this.reserveEntityByIds({
            //     ids: data,
            //   });
            // }

            this.formReceiveModel.storage_id = this.receivedData.storage_id;
            this.itemInputData.item = this.receivedData.trNumber;

            this.handleInputItemSearch(this.receivedData.trNumber);
          }
        }
        setTimeout(this.resetErrorMessages(), 1200, this.resetErrorMessages());
      },
    },

    // receivedData: {
    //   deep: true,
    //   handler(value) {
    //     alert("!!!!!!!!!!");
    //     if (value && value.storage_id) {
    //       this.formModel.entities = value.entities;
    //       this.formModel.storage_id = value.storage_id;
    //     }
    //     setTimeout(this.resetErrorMessages(), 1200, this.resetErrorMessages());
    //   },
    // },

    "receiveModalData.editedId": {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        if (value) {
          ApiService.get(ENDPOINT + value)
            .then(({ data }) => {
              for (var entity of data.entities) {
                entity.item = this.$store.getters.getItemByID(entity.item_id);
                entity.item.reference_number = entity.reference_number;
                entity.item.serial_number = entity.serial_number;
                entity.quantity = entity.new_quantity;
                entity.oldStatusTranslated = this.getTranslatedStatus(
                  entity.old_status
                );
                entity.statusTranslated = this.getTranslatedStatus(
                  entity.new_status
                );
                entity.nameTranslated = this.$helpers.getTranslated(
                  entity.translations
                ).name;
                entity.item.quantity_unit_id = entity.quantity_unit_id;
                // entity.item.comment = entity.comment;
                entity.quantityUnitTranslated =
                  this.$store.getters.getQuantityUnitByID(
                    entity.quantity_unit_id
                  ).name;
              }
              this.formReceiveModel = Object.assign({}, data);
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchStorage", "fetchItem", "fetchQuantityUnit"]),
    handleCloseModalForm() {
      let data = this.formReceiveModel.entities.map((item) => {
        return item.item_entity_id;
      });

      if (this.itemInputData.item_entity_id) {
        data.push(this.itemInputData.item_entity_id);
      }

      if (!this.formReceiveModel.id && data.length > 0) {
        this.unReserveEntityByIds({
          ids: data,
        });
      }

      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formReceiveModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;

        /**Delete unused translations */
        if (TRANSLATED_ATRIBUTES.length > 0) {
          let translations = {};
          i18nService.languages.forEach((item) => {
            let langCode = item.lang;
            if (
              model.translations[langCode] &&
              model.translations[langCode].name != ""
            ) {
              translations[langCode] = Object.assign(
                {},
                model.translations[langCode]
              );
            }
          });
          if (Object.keys(translations).length != 0) {
            model.translations = translations;
          }
        }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleInputItemSearch(value) {
      this.entitySelected = null;
      if (value && typeof value === "object") {
        this.openEntityModalForm({
          // storage_id: this.formReceiveModel.storage_id,
          statuses: [1, 2, 3, 6, 7],
          item_id: this.itemInputData.item.id,
        });
      } else if (value != "") {
        this.openEntityModalForm({
          s: value,
          // storage_id: this.formReceiveModel.storage_id,
          statuses: [1, 2, 3, 6, 7],
        });
      }
    },

    openEntityModalForm(searchObj) {
      ApiService.post(ENDPOINT + "itemEntity/search", searchObj)
        .then((data) => {
          if (data.data.length > 0) {
            let dataTranslated = data.data.map((item) => {
              item.nameTranslated = this.$helpers.getTranslated(
                item.translations
              ).name;
              item.quantityUnitTranslated = this.$helpers.getTranslated(
                item.quantity_unit_translations
              ).name;
              item.statusTranslated = this.getTranslatedStatus(item.status);
              item.isSelectable = !item.reserved_at;
              return item;
            });
            this.entitiesArray = dataTranslated;
            this.entityModal = true;
          } else {
            Swal.fire({
              title: "",
              text: "Entity not found!",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    handleClickEntity(entity) {
      this.unReserveEntityByIds({
        ids: [entity.item_entity_id],
      });
      this.itemInputData = Object.assign({}, entity);
      this.itemInputData.status = 4;

      let editedIndex = this.formReceiveModel.entities.indexOf(entity);
      this.cancelItem = Object.assign({}, entity);
      this.formReceiveModel.entities.splice(editedIndex, 1);
    },

    handleAddToListSelected() {
      let entityIds = this.selectedEntities.map((entity) => {
        return entity.item_entity_id;
      });

      if (this.selectedEntities.length > 0) {
        this.formReceiveModel.entities = [
          ...this.formReceiveModel.entities,
          ...this.selectedEntities.map((entity) => {
            entity.old_status = entity.status;
            entity.status = 4;
            entity.statusTranslated = this.getTranslatedStatus(entity.status);
            entity.oldStatusTranslated = this.getTranslatedStatus(
              entity.old_status
            );
            return entity;
          }),
        ];

        this.reserveEntityByIds({
          ids: entityIds,
        });

        this.entityModal = false;
        this.cancelItem = null;
        this.itemInputData = Object.assign({}, initialItemInputData);
        this.selectedEntities = [];
      }
    },

    handleCancelItem() {
      this.formReceiveModel.entities.push(this.cancelItem);
      this.itemInputData = Object.assign({}, initialItemInputData);
      this.reserveEntityByIds({
        ids: [this.cancelItem.item_entity_id],
      });
      this.cancelItem = null;
      this.$refs.formItem.resetValidation();
    },

    resetInpuDataForm() {
      this.entitiesArray = [];
      this.entitySelected = null;
      this.itemInputData = Object.assign({}, initialItemInputData);
    },

    getTranslatedStatus(id) {
      return this.$t("ADJUSTMENT.status_" + id);
    },

    setTranslatedAttributes() {
      if (!this.formReceiveModel.id) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formReceiveModel.translations[language.lang] = {};
            this.formReceiveModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formReceiveModel.translations[language.lang]) {
              this.formReceiveModel.translations[language.lang] = {};
              this.formReceiveModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },

    handleAddItemToList() {
      this.$refs.formItem.validate();

      if (this.formItemValid) {
        let self = Object.assign({}, this);
        this.reserveEntityByIds({
          ids: [this.itemInputData.item_entity_id],
        }).then((data) => {
          if (data) {
            self.itemInputData.statusTranslated = this.$t(
              "ADJUSTMENT.status_" + self.itemInputData.status
            );
            self.itemInputData.oldStatusTranslated = this.$t(
              "ADJUSTMENT.status_" + self.itemInputData.old_status
            );

            self.formReceiveModel.entities.push(self.itemInputData);
            self.itemInputData = Object.assign({}, initialItemInputData);
          } else {
            Swal.fire({
              title: "",
              text: "The entity is reserved!",
              icon: "error",
            });
          }
        });

        this.cancelItem = null;
        this.entitySelected = null;
        this.itemInputData = Object.assign({}, initialItemInputData);
        this.$refs.formItem.resetValidation();
      }
    },

    resetItemInputData() {
      this.itemInputData.item = null;
      this.itemInputData.quantityUnit = null;
      this.itemInputData.serial_number = null;
      this.itemInputData.quantity = null;
      this.itemInputData.status = null;
      this.itemInputData.old_status = null;
      this.cancelItem = null;
      // this.itemInputData.comment = null;
    },

    reserveEntityByIds(entityIds) {
      return ApiService.post(ENDPOINT + "itemEntity/reserve", entityIds);
    },

    unReserveEntityByIds(entityIds) {
      ApiService.post(ENDPOINT + "itemEntity/unreserve", entityIds)
        .then(({ data }) => {
          return data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.formReceiveModel.entities.indexOf(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let itemToDelete = this.formReceiveModel.entities[this.editedIndex];

      // if (itemToDelete.type != 1) {
      this.unReserveEntityByIds({
        ids: [itemToDelete.item_entity_id],
      });
      // }

      this.formReceiveModel.entities.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = null;
      });
    },

    resetErrorMessages() {
      let errorData = Object.assign({}, initialReceiveFormData());

      if (this.$refs.form) this.$refs.form.resetValidation();

      this.languages.forEach((language) => {
        TRANSLATED_ATRIBUTES.forEach((attribute) => {
          errorData["translations." + language.lang + "." + attribute] = "";
        });
      });
      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("receive") > -1
        );
      }
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "stock.receive." + action);
    },
  },

  async mounted() {
    this.fetchItem();
    this.fetchStorage();
    this.fetchQuantityUnit();
    this.setTranslatedAttributes();
    this.setPermissions();
  },
};
</script>
