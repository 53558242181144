<template>
  <v-card>
    <v-card-title>
      Receives
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="receiveCollection"
      :search="search"
      :sort-by="['rc_number']"
      sort-desc="true"
      @click:row="handleClickItem"
    >
      <template #header="{ props: { headers } }">
        <thead class="v-data-table-header">
          <tr>
            <th></th>
            <th>
              <v-select
                v-model="storageNameFilter"
                :items="[{ storage_name: 'Select' }, ...storageNameCollection]"
                item-text="storage_name"
                item-value="storage_name"
              ></v-select>
            </th>
            <th>
              <v-select
                v-model="nameFilter"
                :items="[{ owner_name: 'Select' }, ...nameCollection]"
                item-text="owner_name"
                item-value="owner_name"
              ></v-select>
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-btn
            v-if="permissionCan('create')"
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            @click="handleNew"
          >
            New receive
          </v-btn>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <!--   <router-link
          :to="`/stock/receives/${item.id}`"
          v-if="permissionCan('view')"
        >
          <v-icon small class="mr-2"> mdi mdi-eye </v-icon>
        </router-link> -->
        <v-icon
          v-if="permissionCan('delete') && item.deletable"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <ReceiveModalForm
      key="adadadasdvv"
      ref="receivelist"
      :receiveModalData="receiveModalData"
      :receivedData="{ storage_id: null, entities: [] }"
      @closeModalForm="handleCloseModalForm"
      @saveModalForm="handleSaveModalForm"
    ></ReceiveModalForm>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import ReceiveModalForm, { initialReceiveFormData } from "./ReceiveModalForm";

export const ENDPOINT = "stock/receive/";

export default {
  name: "Receives",
  components: { ReceiveModalForm },
  data() {
    return {
      showAll: false,
      search: "",
      permissions: [],
      storageNameFilter: "Select",
      nameFilter: "Select",
      headers: [
        {
          text: "Receive ID",
          value: "rc_number"
        },
        {
          text: "Storage",
          value: "storage_name",
          filter: value => {
            if (this.storageNameFilter == "Select") return true;
            return value == this.storageNameFilter;
          }
        },
        {
          text: "User name",
          value: "owner_name",
          filter: value => {
            if (this.nameFilter == "Select") return true;
            return value == this.nameFilter;
          }
        },
        {
          text: "Date",
          value: "created_at"
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false }
      ],

      receiveModalData: {
        dialog: false,
        editedId: null
      },
      editedItem: Object.assign({}, initialReceiveFormData()),

      dialogDelete: false
    };
  },

  computed: {
    ...mapGetters(["receiveCollection"]),
    storageNameCollection() {
      return this.receiveCollection.map(item => {
        return item.storage_name;
      });
    },
    nameCollection() {
      return this.receiveCollection.map(item => {
        return item.owner_name;
      });
    }
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "$route.params.id": {
      handler: function(id) {
        if (id) {
          setTimeout(() => {
            // console.log(
            //   "this.$refs.receive",
            //   this.$refs.receive.handleCloseModalForm
            // );
            this.editItem(id);
            // if (!this.$refs.receive.handleCloseModalForm) {
            //   location.reload();
            // } else {
            // }
          });
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapActions(["fetchReceive"]),
    editItem(id) {
      this.receiveModalData.editedId = id;
      this.receiveModalData.dialog = true;
    },

    handleNew() {
      this.receiveModalData.editedId = null;
      this.receiveModalData.dialog = true;
    },

    handleSaveModalForm() {
      this.handleCloseModalForm();
    },

    handleCloseModalForm() {
      this.receiveModalData = {
        dialog: false,
        editedId: null
      };
      this.fetchReceive();

      if (this.$route.name !== "receives")
        this.$router.push({ name: "receives" });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchReceive();
        })
        .catch(error => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleClickItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        this.$router.push(`/stock/receives/${item.id}`);
      }
    },

    closeDelete() {
      this.receiveModalData.editedId = null;
      this.dialogDelete = false;
    },

    permissionCan(action) {
      return this.permissions.find(x => x.name == "stock.receive." + action);
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          item => item.name.indexOf("receive") > -1
        );
      }
    }
  },

  async mounted() {
    this.fetchReceive();

    this.setPermissions();
    if (!this.$refs.receivelist.handleCloseModalForm) {
      location.reload();
    }
  }
};
</script>
